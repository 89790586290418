import api from "../../api/api";
// import Vue from "vue";
// import $ from "jquery";

const state = {
    all: {
        incoming_orders: [],
        outgoing_orders: [],
        customer_completed_orders: [],
        branch_completed_transfers: [],
    },
    orders_by_type: [],
    orders_complete_or_refund: [],
    orders_by_part_pos: [],
    orders_by_part_order: [],
    orders_by_part_received: [],
    order_sales_history: [],
    incoming_orders_status: [],
    fetchBranchOrders: [],
    active_order: {},
};

const mutations = {
    UPDATE_INCOMING_ORDERS(state, payload) {
        state.all.incoming_orders = payload;
    },
    UPDATE_OUTGOING_ORDERS(state, payload) {
        state.all.outgoing_orders = payload;
    },
    customer_completed_orders(state, payload) {
        state.all.customer_completed_orders = payload;
    },
    branch_completed_transfers(state, payload) {
        state.all.branch_completed_transfers = payload;
    },
    active_order(state, payload) {
        state.active_order = payload;
    },
    orders_by_type(state, payload) {
        state.orders_by_type = payload;
    },
    orders_complete_or_refund(state, payload) {
        state.orders_complete_or_refund = payload;
    },
    orders_by_part_pos(state, payload) {
        state.orders_by_part_pos = payload;
    },
    orders_by_part_received(state, payload) {
        state.orders_by_part_received = payload;
    },
    orders_by_part_order(state, payload) {
        state.orders_by_part_order = payload;
    },
    order_sales_history(state, payload) {
        state.order_sales_history = payload;
    },
    incoming_orders_status(state, payload) {
        state.incoming_orders_status = payload;
    },
    fetchBranchOrders(state, payload) {
        state.fetchBranchOrders = payload; // Simple assignment is enough
    },
    update_order(state, payload) {
        const updatedOrder = payload;
        const index = state.fetchBranchOrders.findIndex(item => item.id === updatedOrder.id);
        if (index !== -1) {
            // Replace the item at index
            state.fetchBranchOrders.splice(index, 1, updatedOrder);
        } else {
            state.fetchBranchOrders.push(updatedOrder);
        }
    },
};

const actions = {

    async fetch_orders_by(context, payload) {
        await api.instance.post("/api/order/incoming", payload.package).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload.package = { context: false, widgets: false };
            } else {
                payload.package = res.data;
            }
            context.commit(`${payload.update}`, payload.package);
        });
    },

    async incoming_orders_status(context, $payload) {
        let payload = {};
        await api.instance.post("/api/order/incoming_orders_status", { 'order_type': $payload }).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = { context: false, widgets: false };
            } else {
                payload = res.data;
            }
            context.commit("incoming_orders_status", payload);
        });
    },
    // async fetchAllOrders(context,payload = 1) {
    //     await api.instance.post("/api/order/fetchOrdersByState",{'period':payload}).then((res) => {
    //         if (!res.data || res.data.length == 0) {
    //             payload = false;
    //         } else {
    //             payload = res.data;
    //         }
    //         context.commit("fetchBranchOrders", payload);
    //     });

    // },
    async fetchAllOrders(context, payload = { period: 1, date: new Date().toISOString().split('T')[0] }) {
        await api.instance.post("/api/order/fetchOrdersByDate", payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("fetchBranchOrders", payload);
        });
    },

    async fetchBranchOrders(context, $payload) {
        let payload = {};
        await api.instance.post("/api/order/fetchBranchOrders", { branch_pos: $payload['branch_pos'],period: $payload['period'], date: new Date().toISOString().split('T')[0] }).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = { context: false, widgets: false };
            } else {
                payload = res.data;
            }
            context.commit("fetchBranchOrders", payload);
        });
    },

    async searchByInvoiceUserInventory(context, $payload) {
        let payload = {};
        await api.instance.post("/api/workflow/fetchOrderByUserProductInvoice", $payload ).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = { context: false, widgets: false };
            } else {
                payload = res.data;
            }
            context.commit("fetchBranchOrders", payload);
        });
    },

    async fetch_orders_by_id(context, payload) {
        await api.instance.get("/api/order/fetchOrderById/" + payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_order", payload);
        });
    },
    async update_order_from_socket(context, payload) {
        console.log('store received instruction from socket', payload)
        context.commit("update_order", payload);
    },
    async incoming_orders_status_search(context, $payload) {
        let payload = {};
        await api.instance.post("/api/order/incoming_orders_status/search", { 'searchKeyword': $payload }).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = { context: false, widgets: false };
            } else {
                payload = res.data;
            }
            context.commit("incoming_orders_status", payload);
        });
    },

    async fetch_order_by_id(context, payload) {
        await api.instance.get("/api/order/fetchOrderById" + payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("active_order", payload);
        });
    },
    async request_order_outgoing_orders(context, payload) {
        await api.instance.get("/api/order/order_status_sender").then((res) => {
            if (!res.data || res.data.length == 0) {
                payload.package = { context: false, widgets: false };
            } else {
                payload.package = res.data;
            }
            context.commit(`${payload.update}`, payload.package);
        });
    },
    async request_order_status_update(context, payload) {
        await api.instance
            .post("/api/order/request_order_status_update", payload.package)
            .then((res) => {
                if (!res.data || res.data.length == 0) {
                    payload.package = { context: false, widgets: false };
                } else {
                    payload.package = res.data;
                }
                context.commit(`${payload.update}`, payload.package);
            });
    },
    async customer_completed_orders(context) {
        let payload = {};
        await api.instance.get("/api/order/customer_completed_orders").then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = { context: false, widgets: false };
            } else {
                payload = res.data;
            }
            context.commit("customer_completed_orders", payload);
        });
    },
    async orders_by_type(context, $payload) {
        let payload = {};
        await api.instance.post("/api/order/orders_by_type", { 'order_type': $payload }).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = { context: false, widgets: false };
            } else {
                payload = res.data;
            }
            context.commit("orders_by_type", payload);
        });
    },
    async orders_complete_or_refund(context) {
        let payload = {};
        await api.instance.post("/api/order/orders_complete_or_refund").then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = { context: false, widgets: false };
            } else {
                payload = res.data;
            }
            context.commit("orders_complete_or_refund", payload);
        });
    },
    async orders_by_type_search(context, $payload) {
        let payload = {};
        await api.instance.post("/api/order/orders_by_type/search", { 'searchKeyword': $payload }).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = { context: false, widgets: false };
            } else {
                payload = res.data;
            }
            context.commit("orders_complete_or_refund", payload);
        });
    },
    async orders_by_part_pos(context, $payload) {
        let payload = {};
        await api.instance.post("/api/order/fetchOrderByPartPOS", { 'searchKeyword': $payload }).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = { context: false, widgets: false };
            } else {
                payload = res.data;
            }
            context.commit("orders_by_part_pos", payload);
        });
    },
    async orders_by_part_received(context, $payload) {
        let payload = {};
        await api.instance.post("/api/order/fetchOrderByPartReceived", { 'searchKeyword': $payload }).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = { context: false, widgets: false };
            } else {
                payload = res.data;
            }
            context.commit("orders_by_part_received", payload);
        });
    },
    async orders_by_part_order(context, $payload) {
        let payload = {};
        await api.instance.post("/api/order/fetchOrderByPartOrder", { 'searchKeyword': $payload }).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = { context: false, widgets: false };
            } else {
                payload = res.data;
            }
            context.commit("orders_by_part_order", payload);
        });
    },
    async order_sales_history(context, $payload) {
        let payload = {};
        await api.instance.post("/api/order/order_sales_history", { 'order_type': $payload }).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = { context: false, widgets: false };
            } else {
                payload = res.data;
            }
            context.commit("order_sales_history", payload);
        });
    },
    async order_sales_history_search(context, $payload) {
        let payload = {};
        await api.instance.post("/api/order/order_sales_history/search", { 'searchKeyword': $payload }).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = { context: false, widgets: false };
            } else {
                payload = res.data;
            }
            context.commit("order_sales_history", payload);
        });
    },

    async branch_completed_transfers(context) {
        let payload = {};
        await api.instance.get("/api/order/branch_completed_transfers").then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = null;
            } else {
                payload = res.data;
            }
            context.commit("branch_completed_transfers", payload);
        });
    },
    async branch_completed_transfers_search(context, $payload) {
        let payload = {};
        await api.instance.post("/api/order/branch_completed_transfers/search", { 'searchKeyword': $payload }).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = { context: false, widgets: false };
            } else {
                payload = res.data;
            }
            context.commit("branch_completed_transfers", payload);
        });
    },
    async ticket_quote(context, payload) {
        await api.instance.post("/api/order/ticket_quote", payload.package).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload.package = { context: false, widgets: false };
            } else {
                payload.package = res.data;
            }
            context.commit(`${payload.update}`, payload.package);
        });
    },
};

const getters = {
    all: (state) => state.all,
    incoming_orders: (state) => state.all.incoming_orders,
    outgoing_orders: (state) => state.all.outgoing_orders,
    customer_completed_orders: (state) => state.all.customer_completed_orders,
    branch_completed_transfers: (state) => state.all.branch_completed_transfers,
    active_order: (state) => state.active_order,
    orders_by_type: (state) => state.orders_by_type,
    orders_complete_or_refund: (state) => state.orders_complete_or_refund,
    order_sales_history: (state) => state.order_sales_history,
    incoming_orders_status: (state) => state.incoming_orders_status,
    orders_by_part_pos: (state) => state.orders_by_part_pos,
    orders_by_part_received: (state) => state.orders_by_part_received,
    orders_by_part_order: (state) => state.orders_by_part_order,
    fetch_Branch_Orders: (state) => state.fetchBranchOrders,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
